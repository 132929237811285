<template>
	<div v-loading="loadingUpload">
		<div style="">
			<div style="line-height: 180%;">
				<el-alert type="warning">
					<div slot="title" style="font-size: 16px; ">{{$t('i18nn_daaaeb1b7b22b126')}}</div>
					<div style="font-size: 14px; ">
						<div>{{$t('FormMsg.Select_long_whLabelFileUpload_1')}}</div>
						<div>2、根据运单号自动识别并保存;上传完后，请等待结果，预计60秒内匹配完成，文件越大等待越长。</div>
						<!-- <div>3、上传完后，请等待结果，预计60秒内匹配完成，文件越大等待越长。</div> -->
						<div>3、只有已提交状态才可以匹配</div>
						<div>4、不可覆盖原面单地址</div>

					</div>
				</el-alert>
			</div>
			<!-- <el-card> -->
			<!-- <div slot="header" class="">
					<h3>
						<i class="el-icon-upload"></i>
						<span>{{$t('i18nn_c5378920e015b12f')}}</span>
					</h3>
				</div> -->

			<HyElWhUpLoad ref="HyElWhUpLoad" :listType="'text'" :watchKey="fileWatchKey" :fileListShow="fileList"
				:fileId="fileId" :folder="'label_truckingNo/'" :accept="'application/pdf'"
				:btnText="$t('i18nn_e11679a643689255')" :maxSizeMB="100" :limit="1"
				v-on:UploadSuccessUrl="FileUploadSuccessUrl"></HyElWhUpLoad>

			<div style="margin-top: 10px;">
				<el-button type="warning" @click="submitExcelFormAction()" icon="el-icon-upload2">
					<span>{{$t('i18nn_926b52e41c45d835')}}</span></el-button>
			</div>

			<!-- </el-card> -->


			<el-card>
				<div slot="header" class="">
					<h3>
						<span>{{$t('i18nn_8aad52f19406c72d')}}</span>
						<el-button type="success" icon="el-icon-refresh" size="small"
							@click="initData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button>

					</h3>
				</div>
				<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
					<el-table id="ex_table" ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%"
						size="small" :max-height="$store.state.frameConHeightWh4">
						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
							:label="$t('Storage.tableColumn.no')"></el-table-column>

						<el-table-column prop="statusName" :label="$t('i18nn_6cdece641436d7ab')">
							<template slot-scope="scope">
								<el-tag type=""
									v-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="success"
									v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<el-tag type="warning"
									v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
								<!-- <el-tag type="danger"
									v-else-if="'30'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag> -->
								<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
							</template>
						</el-table-column>

						<!-- <el-table-column prop="typeName" :label="$t('i18nn_184333c81babf2f1')">
							<template slot-scope="scope">
								<el-tag type="" v-if="'0' == scope.row.type">{{ $Utils.i18nKeyText(scope.row,'typeName') }}</el-tag>
								<el-tag type="success"
									v-else-if="'10' == scope.row.type">{{ $Utils.i18nKeyText(scope.row,'typeName') }}</el-tag>
								<el-tag type="warning"
									v-else-if="'20' == scope.row.type">{{ $Utils.i18nKeyText(scope.row,'typeName') }}</el-tag>
								<el-tag type="danger"
									v-else-if="'30'==scope.row.type">{{ $Utils.i18nKeyText(scope.row,'typeName') }}</el-tag>
								<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'typeName') }}</el-tag>
							</template>
						</el-table-column> -->



						<el-table-column prop="reUrl" :label="$t('i18nn_ad810b5b659860fe')" show-overflow-tooltip>
							<template slot-scope="scope">
								<a :href="scope.row.reUrl" target="_blank">{{ scope.row.reUrl }}</a>
							</template>
						</el-table-column>

						<el-table-column prop="indexPage" :label="$t('i18nn_d426ae19e98559b5')"></el-table-column>

						<el-table-column prop="relationNo" :label="$t('i18nn_5e7740c52e22eefc')"></el-table-column>


						<el-table-column prop="totalJson" :label="$t('i18nn_8c7713cae892514d')" min-width="100">
							<template slot-scope="scope">
								<div v-if="scope.row.totalJson">
									<div>
										<span>{{$t('i18nn_c524cff2488f2ebd')}}</span>:
										<strong style="color:#409EFF;">{{scope.row.totalJson.success}}</strong>
									</div>
									<div>
										<span>{{$t('i18nn_7a4aff235c9a01dd')}}</span>:
										<strong style="color:#F56C6C;">{{scope.row.totalJson.fail}}</strong>
									</div>
									<div>
										<span>{{$t('i18nn_cce168301447d1ba')}}</span>:
										<strong style="color:#67C23A;">{{scope.row.totalJson.handing}}</strong>
									</div>
								</div>
							</template>
						</el-table-column>

						

						<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')">
							<template slot-scope="scope">
								<el-popover placement="top" trigger="hover">
									<div class="pre-text">{{ scope.row.remark }}</div>
									<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
								</el-popover>
							</template>
						</el-table-column>
						<el-table-column prop="createTime" :label="$t('i18nn_2594059036dfede5')" min-width="180">
							<template slot-scope="scope">
								<div>
									<div>
										<span>{{$t('i18nn_e8564657bbe9ca53')}}</span>:<span>{{scope.row.createTime}}</span>
									</div>
									<div>
										<span>{{$t('i18nn_e1911e9360047dcb')}}</span>:<span>{{scope.row.updateTime}}</span>
									</div>
								</div>
							</template>
						</el-table-column>
						<!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')">
						</el-table-column> -->

						<el-table-column :label="$t('Storage.tableColumn.operation')" width="150px" fixed="right">
							<template slot-scope="scope">
								<div>
									<div style="margin-bottom: 5px;">
										<el-button @click="openDet($event, scope.row)" type="primary" size="mini"
											icon="el-icon-document">{{$t('i18nn_75247a5e05331f7f')}}</el-button>
									</div>
									<div style="margin-bottom: 5px;">
										<el-button @click="resetNum($event, scope.row)" type="warning" size="mini"
											icon="el-icon-refresh-left">重置匹配次数</el-button>
									</div>

								</div>
							</template>
						</el-table-column>
					</el-table>

					<div class="tableCon_pagination">
						<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
					</div>
				</div>
			</el-card>

		</div>

		<whLabelFileAutoMatchDet :openTime="MatchDetOpenTime" :id="MatchDetId"></whLabelFileAutoMatchDet>

	</div>
</template>
<script>
	import HyElWhUpLoad from '@/components/Common/HyElWhUpLoad.vue';

	import whLabelFileAutoMatchDet from '@/components/StorageCenter/shipments/whLabelFileAutoMatchDet.vue';

	export default {
		props: {
			// fileKey: {
			// 	default: function() {
			// 		return 'label';
			// 	},
			// 	type: String
			// },
			openTime: {

			},

		},
		components: {
			HyElWhUpLoad,
			whLabelFileAutoMatchDet
		},
		data() {
			return {
				loadingUpload: false,
				//附件
				fileWatchKey: 'truckingNo',
				fileList: [],

				fileId: this.$store.getters.getUserInfo.id,
				loadingFile: false,

				saveErr: false,

				listUrl: [],

				loading_load: false,
				tableData: [],

				MatchDetOpenTime: "",
				MatchDetId: "",

				selectOption: {

				},

				//分页数据
				pagination: this.$Utils.defaultPagination(),

				filterData: {

				},

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				// this.dialogFile = true;
				this.initFile();
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.initData();
		},
		methods: {
			initFile() {
				// this.stepActive = 0;
				//初始化上传控件
				this.listUrl = [];
				this.saveErr = false;
				this.$nextTick(() => {
					try {
						this.$refs.HyElWhUpLoad.initData();
						this.$refs.HyElWhUpLoad.clearFileList();
					} catch (err) {
						console.log(err);
					}
				});
			},
			initData() {
				//刷新历史匹配
				this.pagination.current_page = 1;
				// this.pagination.total = 0;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.getPageData();

			},

			//上传成功回调
			FileUploadSuccessUrl(val) {
				console.log('changeFileUrl', val);
				let listUrl = val.map(item => {
					// "filePath":"","fileName"：“”
					return {
						filePath: item.url,
						fileName: item.name
					};
				});
				console.log('listUrl', listUrl);
				this.listUrl = listUrl;
				this.submitExcelFormData();
			},
			//校验-
			submitExcelFormAction() {
				if (this.saveErr && this.listUrl.length > 0) {
					// 校验到后台有误时
					this.submitExcelFormData();
				} else {
					//重新执行-oss 文件上传及回调
					this.$nextTick(() => {
						try {
							this.$refs.HyElWhUpLoad.submitUpload();
						} catch (err) {
							console.log(err);
						}
					});
				}
			},
			//校验-
			submitExcelFormData() {
				if (this.listUrl.length <= 0) {
					this.$message.warning(this.$t('i18nn_46d9c96bfde09a4a'))
					return;
				}
				let formData = {
					"reUrl": this.listUrl[0].filePath,
					"fileName": this.listUrl[0].fileName,
				};
				this.submitExcelForm(formData);
			},
			//校验-
			submitExcelForm(formData) {
				this.loadingUpload = true;
				this.$http
					.put(this.$urlConfig.whMatchingLabelByTrackingNumberPdf, formData)
					.then(({
						data
					}) => {
						console.log(data);
						this.loadingUpload = false;
						if (200 == data.code) {
							// this.stepActive = 1;
							this.initFile();
							this.getPageData();
							this.saveErr = false;
						} else {
							this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
								type: 'warning'
							});
							this.saveErr = true;
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.submitError'));
						this.loadingUpload = false;
						this.saveErr = true;
						this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					});
			},

			//分页的筛选项数据
			pageFilterData() {

				return {

				};
			},

			//请求分页数据
			getPageData() {
				// let _this = this;

				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目
					},
					this.pageFilterData()
				);

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.whMatchingLabelPdfSourcePage, filterData)
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('商家账单列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error('列表数据，请求失败！');
						this.loading_load = false;
					});
			},

			//查看详情
			openDet(event, row) {
				event.stopPropagation();
				this.MatchDetOpenTime = new Date().getTime();
				this.MatchDetId = row.id;
			},

			//
			resetNum(event, row) {
				event.stopPropagation();
				this.$confirm('确定重置匹配次数?', this.$t('tips.tipsTitle'), {
						type: 'warning'
					})
					.then(() => {
						this.postData(this.$urlConfig.whMatchingResetNum + "/" + row.id + "/matchs", {},'',()=>{
							this.$message.success("提交成功");
							this.getPageData();
						});
					})
					.catch(() => {});
			},

			closeAction() {
				this.$emit('close')
			},
			
			//提交信息
			postData(url, formData, type, callback) {
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				HttpType.then(({
					data
				}) => {
					this.loading_load = false;
					if (200 == data.code) {
							callback();
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					// console.log(this.$t('tips.submitError'));
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>